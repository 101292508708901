import apiClient from '@/services/axios'

const getAll = async () => {
  const res = await apiClient.get('/doctor')
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAllSpecializations = async () => {
  const res = await apiClient.get('/api/doctors/specializations')
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getDoctorBySpecialization = async (specializationId) => {
  const res = await apiClient.get(`/api/doctors/filter?filter_specialization_id=${specializationId}`)
  if (res) {
    return res.data
  } else {
    return []
  }

}

const updateDoctorSpecialization = async (doctorId, specializationId) => {
  const res = await apiClient.put(`/api/doctors/`, {
    id: doctorId,
    specialization_id: specializationId,
  })
  if (res) {
    return res.data
  } else {
    return []
  }

}

const getApproved = async () => {
  const res = await apiClient.get('/doctor?status=approved')
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getById = async (id) => {
  const res = await apiClient.get(`/doctor/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const deleteById = async (id) => {
  const res = await apiClient.delete(`/doctor/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const create = async (data) => {
  const res = await apiClient.post('/doctor', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const editById = async (id, data) => {
  const res = await apiClient.patch(`/doctor/${id}`, data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

export {
  getAll,
  getAllSpecializations,
  getDoctorBySpecialization,
  updateDoctorSpecialization,
  getById,
  deleteById,
  create,
  editById,
  getApproved,
}
